<template>
  <div
      class="sticky top-0 left-0 z-100"
      :class="{
        'h-56': !cropped && !hideNotRequired,
        'h-24': cropped,
        'mb-[15px]': hideNotRequired && !cropped,
      }"
  >
    <div
        class="flex h-33 flex-col rounded-t p-4 text-white"
        :class="{
          'bg-gradient-to-r from-primary-500 via-primary-600 via-60% to-orange-400 to-90%': hasSpecialParticularities,
          'bg-gradient-to-r from-primary-500 to-primary-600 ': !hasSpecialParticularities,
          'pl-44': !cropped && !hideNotRequired,
          'pl-20': cropped,
          'pl-32': hideNotRequired && !cropped,
        }"
    >
      <div class="flex flex-col w-full gap-y-3 box-border">
        <div
            class="flex flex-1 flex-row gap-x-2 w-full items-center justify-between"
            :class="{
              'hidden': cropped,
            }"
        >
          <div class="text-2xl font-bold leading-none capitalize-first whitespace-nowrap">
            {{ civiliteFormated }}
          </div>

          <div class="text-2xl font-bold leading-none capitalize-first whitespace-nowrap">
            {{ funeralHomeName }}
          </div>
        </div>

        <div class="flex flex-1 flex-row gap-x-2 w-full items-center justify-between">
          <div
              class="
                text-3xl font-bold capitalize whitespace-nowrap
                overflow-hidden w-full text-ellipsis inline-block
              "
          >
            {{ dossier.concernedPerson.full_name }}
          </div>

          <div
              class="flex flex-row justify-end"
              :class="{
                'hidden': cropped,
              }"
          >
            <div>
              <AvatarComponent
                  :user_id="dossier.responsable_id"
                  :badge="true"
                  size="8"
              />
            </div>
          </div>
        </div>

        <div
            class="flex flex-1 flex-row gap-x-2 w-full items-center justify-between"
            :class="{
              'hidden': cropped,
            }"
        >
          <div
              class="
                text-md whitespace-nowrap text-ellipsis
                overflow-hidden inline-block w-full
              "
          >
            {{
              $h.formatDate(
                  dossier.concernedPerson.birth_date,
                  'L',
                  $i18n.locale.substring(0, 2)
              )
            }}
            {{
              dossier.concernedPerson.death_date_time
                  ? '-' +
                  $h.formatDate(
                      dossier.concernedPerson.death_date_time,
                      'L',
                      $i18n.locale.substring(0, 2)
                  ) +
                  ' (' +
                  dossier.concernedPerson.age +
                  ')'
                  : ''
            }}
          </div>

          <div
              class="flex flex-row gap-x-1"
          >
            <div
                v-if="!!dossier.properties.destitute"
                class="rounded-md bg-rose-600 px-2 py-0.5 text-xs font-medium text-contrast-primary capitalize-first whitespace-nowrap"
            >
              {{ $t('attributes.destitute') }}
            </div>
            <div
                v-if="!!dossier.properties.funeral_in_strict_privacy"
                class="rounded-md bg-rose-600 px-2 py-0.5 text-xs font-medium text-contrast-primary capitalize-first whitespace-nowrap"
            >
              {{ $t('attributes.funeral_in_strict_privacy') }}
            </div>
            <div
                v-if="!!dossier.properties.no_flower"
                class="rounded-md bg-rose-600 px-2 py-0.5 text-xs font-medium text-contrast-primary capitalize-first whitespace-nowrap"
            >
              {{ $t('attributes.no_flower') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="flex flex-row gap-4 px-4"
        :class="{
          'mt-[-7.5rem]': !cropped && !hideNotRequired,
          'mt-[-62px]': cropped,
          'mt-[-126px]': hideNotRequired && !cropped,
        }"
    >
      <div class="flex-shrink-0">
        <div
            class="group/dossier-picture relative flex aspect-photo overflow-hidden rounded-md border border-slate-300 bg-white p-0.5 shadow-xl"
            :class="{
              'h-48': !cropped && !hideNotRequired,
              'h-14': cropped,
              'h-28': hideNotRequired && !cropped,
            }"
        >
          <img
              v-if="dossier?.pictures?.picture_rectangle?.original_url"
              :src="dossier.pictures.picture_rectangle.original_url"
              class="overflow-hidden rounded object-cover"
              :alt="dossier.concernedPerson.full_name"
          />
          <div
              v-else
              class="absolute inset-0 flex flex-row items-center justify-center text-primary-200"
          >
            <FontAwesomeLayers class="fa-5x">
              <FontAwesomeIcon :icon="['fal', 'circle']"/>
              <FontAwesomeIcon
                  :icon="['fas', 'camera']"
                  transform="shrink-7"
              />
            </FontAwesomeLayers>
          </div>
          <div
              class="absolute bottom-1 right-1 flex cursor-pointer flex-row items-center justify-center"
              :class="{
                'hidden': cropped || hideNotRequired,
              }"
          >
            <RouterLink
                :to="
                dossier?.pictures?.picture
                  ? { name: 'dossier_picture_preview_route' }
                  : { name: 'dossier_gallery_list_route' }
              "
                class="rounded bg-primary-100/75 p-0.5 text-gray-600"
            >
              <FontAwesomeIcon :icon="['fal', 'edit']" fixed-width/>
            </RouterLink>
          </div>
        </div>
      </div>

      <div
          v-if="!hideNotRequired"
          class="mt-32 flex flex-1 flex-row text-black"
          :class="{
            'hidden': cropped,
          }"
      >
        <div class="flex flex-1 flex-row gap-2">
          <div>
            <div
                class="flex flex-row divide-x divide-slate-300 overflow-hidden rounded-md border border-slate-300 bg-white shadow-xl"
            >
              <div class="flex flex-row gap-4 overflow-hidden bg-white p-2">
                <RouterLink
                    :to="{ name: 'dossier_form_site_route' }"
                    class="flex h-8 w-8 cursor-pointer items-center justify-center text-xs font-bold"
                >
                  WWW
                </RouterLink>
              </div>
              <div
                  v-if="$can('super-admin')"
                  class="flex flex-row gap-4 overflow-hidden bg-white p-2"
              >
                <RouterLink
                    :to="{ name: 'dossier_screen_route' }"
                    class="flex h-8 w-8 items-center justify-center"
                >
                  <FontAwesomeIcon :icon="['fal', 'tv']" size="lg"/>
                </RouterLink>
              </div>
            </div>
          </div>
          <div>
            <PartnersCard
                :dossier_partner="dossier.partner_sharings"
                :funeral_home_partner="dossier?.funeral_home?.funeral_home_partner_enabled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AvatarComponent from "@c/AvatarComponent.vue";
import PartnersCard from "@/views/dossier/form/subpages/summary/PartnersCard.vue";
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import {mapGetters} from "vuex";

export default {
  name: 'DossierInfosHeader',
  components: {
    PartnersCard,
    AvatarComponent,
    FontAwesomeLayers,
    FontAwesomeIcon,
  },
  props: {
    containerScrollName: {
      type: String,
      required: false,
      default: null,
    },
    hideNotRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {

    return {
      cropped: false,
      containerScroll: null,
    }
  },
  computed: {
    ...mapGetters({
      dossier: 'dossier/getDossier',
      civilities: 'metadata/getCivilityMetadata',
      getFuneralHome: 'metadata/getFuneralHomeMetadata',
    }),
    hasSpecialParticularities() {
      return (
          !!this.dossier.properties.no_flower ||
          !!this.dossier.properties.funeral_in_strict_privacy ||
          !!this.dossier.properties.destitute
      )
    },
    civiliteFormated() {
      return (
          this.civilities.find(
              (civility) => civility.id === this.dossier.concernedPerson.civility
          )?.name ?? null
      )
    },
    funeralHomeName() {
      return this.getFuneralHome(this.dossier.funeral_home_id)?.name
    },
  },
  mounted() {
    this.checkScrollEvent()
  },
  methods: {
    checkScrollEvent() {
      if (this.containerScrollName) {
        this.containerScroll = document.getElementById(this.containerScrollName)

        if (this.containerScroll) {
          this.containerScroll.addEventListener('scroll', (e) => {
            const scrollTop = this.containerScroll.scrollTop

            if (scrollTop >= 75) {
              if (!this.cropped) {
                this.cropped = true;
              }
            }

            if (scrollTop === 0) {
              if (this.cropped) {
                this.cropped = false;
              }
            }
          })
        }
      }
    },
  }
}
</script>
