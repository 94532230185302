import apiClient from '@u/apiClient'

export default {
  fetchNewImport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new', {
          params: {
            model: 'Import'
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getImport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/import/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  createImport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post('/import', payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateImport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`/import/${payload.id}`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchImportList({}) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/import`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  deleteImport({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`/import/${payload.import_id}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  startImportTask({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(
          `/import/${payload.import_id}/task/${payload.import_task_id}/start`
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  createImportTask({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/import/${payload.import_id}/task`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getImportTask({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/import/${payload.import_id}/task/${payload.task_id}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getImportTaskStatus({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/import/${payload.import_id}/task/${payload.task_id}/status`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  deleteImportTask({}, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`/import/${payload.import_id}/task/${payload.task_id}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
