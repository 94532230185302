<template>
  <template v-if="size === 'mini'">
    <router-link
      class="group flex flex-row items-center p-2"
      :to="{ name: routeName, params: { id: $route.params.id } }"
    >
      <div class="relative flex-shrink-0">
        <img :src="logo_url" class="h-8 w-8 object-contain" />
        <div
          v-if="size === 'mini'"
          class="absolute -bottom-1 -right-1 h-2 w-2 rounded-full"
          :class="`bg-${statusBgColor} text-${statusTxtColor}`"
        ></div>
      </div>
      <div
        class="w-0 overflow-hidden font-medium group-hover:w-full group-hover:pl-3"
      >
        {{ name }}
      </div>
    </router-link>
  </template>
  <template v-else>
    <div class="flex flex-1 flex-col">
      <router-link
        class="truncate text-sm font-medium"
        :to="{ name: routeName, params: { id: $route.params.id } }"
      >
        {{ name }}
      </router-link>
      <div class="flex justify-end">
        <span
          class="rounded-md px-2 py-0.5 text-xs"
          :class="`bg-${statusBgColor} text-${statusTxtColor}`"
          >{{ statusLabel }}</span
        >
      </div>
    </div>
  </template>
</template>

<script>
export default {
  name: 'PartnerCard',
  props: {
    sended: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    logo_url: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'mini'
    }
  },
  computed: {
    statusBgColor() {
      return this.sended ? 'green-500' : 'red-500'
    },
    statusTxtColor() {
      return this.sended ? 'white' : 'white'
    },
    statusLabel() {
      return this.$t('global.sended')
    }
  }
}
</script>
