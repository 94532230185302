<template>
  <div class="flex flex-col gap-2 rounded border py-4">
    <div class="flex flex-row items-start px-4">
      <div class="flex flex-1 flex-col gap-2">
        <div>
          <span class="rounded bg-primary px-2 py-0.5 text-contrast-primary">
            {{ importTypesFormated }}
          </span>
        </div>
        <div>
          <FuneralManagerV2ImportSettings
            :settings="import_.settings"
            :errors="{}"
          />
        </div>
      </div>
      <div class="flex shrink-0 items-start gap-1">
        <button
          class="flex aspect-square w-8 items-center justify-center rounded-full bg-primary text-contrast-primary"
          @click="addNewTask"
        >
          <FontAwesomeIcon :icon="['fas', 'plus']" fixed-width />
        </button>
        <button
          class="flex aspect-square w-8 items-center justify-center rounded-full bg-red-600 text-white"
          @click="$emit('delete', import_.id)"
        >
          <FontAwesomeIcon :icon="['fas', 'trash']" fixed-width />
        </button>
      </div>
    </div>
    <div v-if="newForm" class="px-4">
      <div class="rounded border p-4">
        <FuneralManagerV2ImportTaskSettings
          v-model:settings="newImportTask.settings"
          :edition-mode="true"
        />
        <div class="flex justify-end">
          <BaseButton
            :title="$t('button.add')"
            icon="plus"
            size="small"
            fixed-width
            @click="saveImportTask"
          />
        </div>
      </div>
    </div>
    <div v-if="import_.import_tasks.length" class="flex flex-col gap-2 p-4">
      <ImportTaskComponent
        v-for="(importTask, i) in import_.import_tasks"
        :key="importTask.id"
        v-model:import-task="import_.import_tasks[i]"
        :task-type="import_.type"
        @delete="deleteImportTask"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ImportTaskComponent from '@/views/sa_management/import/component/ImportTaskComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FuneralManagerV2ImportTaskSettings from '@/views/sa_management/import/component/specific/FuneralManagerV2ImportTaskSettings.vue'
import FuneralManagerV2ImportSettings from '@/views/sa_management/import/component/specific/FuneralManagerV2ImportSettings.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'ImportViewComponent',
  components: {
    BaseButton,
    FuneralManagerV2ImportSettings,
    FuneralManagerV2ImportTaskSettings,
    FontAwesomeIcon,
    ImportTaskComponent
  },
  props: {
    import_: {
      type: Object,
      required: true
    }
  },
  emits: ['updated', 'delete'],
  data() {
    return {
      newForm: false,
      newImportTask: {
        settings: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      import_types: 'metadata/getImportTypes'
    }),
    importTypesFormated() {
      return (
        this.import_types.find(
          (import_type) => import_type.id === this.import_.type
        )?.name ?? this.import_.type
      )
    }
  },
  methods: {
    ...mapActions({
      processDeleteImportTaskAction: 'saManagement/deleteImportTask',
      processCreateImportTaskAction: 'saManagement/createImportTask'
    }),
    addNewTask() {
      this.newForm = true
    },
    saveImportTask() {
      this.processCreateImportTaskAction({
        import_id: this.import_.id,
        ...this.newImportTask
      }).then((response) => {
        this.$emit('updated')
        this.newForm = false
      })
    },
    deleteImportTask(import_task_id) {
      this.processDeleteImportTaskAction({
        import_id: this.import_.id,
        task_id: import_task_id
      }).then((response) => {
        if (response.success) {
          this.$emit('updated')
        }
      })
    }
  }
}
</script>
