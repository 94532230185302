<template>
  <div
    v-if="partners_list.length"
    class="flex"
    :class="[orientation === 'horizontal' ? 'flex-row' : 'flex-col']"
  >
    <h2
      v-if="orientation !== 'horizontal'"
      class="flex-1 pl-2 text-xl font-bold capitalize-first"
    >
      {{ $t('global.partners') }}
    </h2>
    <div
      class="flex divide-slate-300 overflow-hidden rounded-md border border-slate-300 bg-white shadow-xl"
      :class="[
        orientation === 'horizontal' ? 'flex-row divide-x' : 'flex-col divide-y'
      ]"
    >
      <template v-for="partner in partners_list" :key="partner.id">
        <!--       ok {{partner}}-->
        <PartnerCard
          :name="partner.name"
          :status-label="partner.status_label"
          :status-bg-color="partner.status_bg_color"
          :status-txt-color="partner.status_txt_color"
          :logo_url="PartnersLogo[partner.id]"
          :route-name="partner.route_name"
          :sended="
            dossier_partner &&
            Array.isArray(dossier_partner) &&
            dossier_partner.find(
              (item) => item.partner.toUpperCase() === partner.id.toUpperCase()
            )
          "
          size="mini"
        />
      </template>
    </div>
  </div>
</template>

<script>
import PartnerCard from '@/views/dossier/form/subpages/summary/PartnerCard.vue'
import PartnersLogo from '@/assets/enums/config/PartnersLogo'

export default {
  name: 'PartnersCard',
  components: { PartnerCard },
  props: {
    orientation: {
      type: String,
      required: false,
      default: 'horizontal'
    },
    funeral_home_partner: {
      type: Array,
      required: true
    },
    dossier_partner: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      partners: [
        {
          id: 'enaos',
          name: 'eNaos',
          route_name: 'dossier_enaos_route',
          logo_url: 'enaos.svg'
        },
        {
          id: 'legacio',
          name: 'Legacio',
          route_name: 'dossier_legacio_route',
          logo_url: 'my-morpheus.svg'
        },
        {
          id: 'funebra',
          name: 'Funebra',
          route_name: 'dossier_funebra_route',
          logo_url: 'funebra.svg'
        },
        {
          id: 'fragment',
          name: 'Fragment',
          route_name: 'dossier_fragment_route',
          logo_url: 'fragment_mini.png'
        },
        {
          id: 'sorenir',
          name: 'Sorenir',
          route_name: 'dossier_sorenir_route',
          logo_url: 'sorenir.svg'
        },
        {
          id: 'cybille',
          name: 'Cybille',
          route_name: 'dossier_cybille_route',
          logo_url: 'cybille.svg'
        },
        {
          id: 'heaven',
          name: 'Heaven',
          route_name: 'dossier_heaven_route',
          logo_url: 'heaven.svg'
        },
        {
          id: 'fune_up',
          name: 'FuneUp',
          route_name: 'dossier_fune_up_route',
          logo_url: 'funeup.svg'
        }
      ]
    }
  },
  computed: {
    PartnersLogo() {
      return PartnersLogo
    },
    partners_list() {
      return this.partners.filter((partner) =>
        this.funeral_home_partner?.some(
          (funeral_home_partner) =>
            funeral_home_partner.partner === partner.id &&
            funeral_home_partner.enabled
        )
      )
    }
  }
}
</script>

<style scoped></style>
