<template>
  <div class="flex flex-col gap-4 rounded border p-4">
    <div class="flex flex-row">
      <div class="flex flex-1 flex-col gap-2">
        <div>
          <span class="rounded bg-primary px-2 py-0.5 text-contrast-primary">
            {{ importTask.status }}
          </span>
        </div>
        <div v-if="importTask.started_at" class="flex gap-1">
          <span>Start at</span>
          <span>{{ $h.formatDate(importTask.started_at, 'LLL') }}</span>
        </div>
        <div v-if="importTask.ended_at" class="flex gap-1">
          <span>Ended at</span>
          <span>{{ $h.formatDate(importTask.ended_at, 'LLL') }}</span>
        </div>
        <div
          v-if="importTask.started_at && !importTask.ended_at"
          class="flex gap-1"
        >
          <span>Elapsed time</span>
          <span>{{ elapsedTime }}</span>
        </div>
        <div
          v-if="importTask.started_at && importTask.ended_at"
          class="flex gap-1"
        >
          <span>Duration time</span>
          <span>{{ durationTime }}</span>
        </div>
      </div>
      <div class="shrink-0">
        <button
          v-if="['pending'].includes(importTask.status)"
          class="flex aspect-square w-8 items-center justify-center rounded-full bg-primary text-contrast-primary"
          @click="startImportTask"
        >
          <FontAwesomeIcon
            :icon="['fas', 'circle-play']"
            fixed-width
            size="lg"
          />
        </button>
        <button
          v-if="['waiting', 'running'].includes(importTask.status)"
          class="flex aspect-square w-8 items-center justify-center rounded-full bg-primary text-contrast-primary"
          @click="stop"
        >
          <FontAwesomeIcon
            :icon="['fas', 'circle-stop']"
            fixed-width
            size="lg"
          />
        </button>
        <button
          v-if="['error', 'waiting', 'finished'].includes(importTask.status)"
          class="flex aspect-square w-8 items-center justify-center rounded-full bg-red-600 text-white"
          @click="deleteImportTask"
        >
          <FontAwesomeIcon :icon="['fas', 'trash']" fixed-width />
        </button>
      </div>
    </div>
    <div v-if="batch" class="flex flex-col gap-2">
      <div class="flex flex-row">
        <div class="flex shrink-0 flex-row rounded border">
          <div class="border-r px-2 py-1">
            {{ batch.processedJobs }}
          </div>
          <div class="px-2 py-1">{{ batch.totalJobs }}</div>
        </div>
      </div>
      <div class="w-full rounded-full bg-gray-200 dark:bg-gray-700">
        <div
          class="rounded-full bg-blue-600 p-0.5 text-center text-xs font-medium leading-none text-blue-100"
          :style="{
            width: `${batch.progress}%`
          }"
        >
          {{ progressText }}
        </div>
      </div>
    </div>
    <div>
      <button
        v-if="!viewSettings"
        class="text-primary"
        @click="viewSettings = true"
      >
        view settings
      </button>
      <div v-if="viewSettings">
        <FuneralManagerV2ImportTaskSettings
          v-if="taskType === 'funeral_manager_v2'"
          :settings="importTask.settings"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FuneralManagerV2ImportTaskSettings from '@/views/sa_management/import/component/specific/FuneralManagerV2ImportTaskSettings.vue'
import moment from 'moment'

export default {
  name: 'ImportTaskComponent',
  components: { FuneralManagerV2ImportTaskSettings, FontAwesomeIcon },
  props: {
    importTask: {
      type: Object,
      required: true
    },
    taskType: {
      type: String,
      required: true
    }
  },
  emits: ['update:importTask', 'deleted', 'updated', 'delete'],
  data() {
    return {
      timeout: null,
      batch: null,
      viewSettings: false
    }
  },
  computed: {
    localImportTask: {
      get() {
        return this.importTask
      },
      set(value) {
        this.$emit('update:importTask', value)
      }
    },
    elapsedTime() {
      let minutes = moment().diff(moment(this.importTask.started_at), 'minutes')
      return this.$h.durationHumanized(minutes)
    },
    durationTime() {
      let minutes = moment(this.importTask.ended_at).diff(
        moment(this.importTask.started_at),
        'minutes'
      )
      return this.$h.durationHumanized(minutes)
    },
    progressText() {
      return this.batch?.progress > 10 ? `${this.batch.progress} %` : ''
    }
  },
  unmounted() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  mounted() {
    this.getImportTask()
  },
  methods: {
    ...mapActions({
      processGetImportTaskAction: 'saManagement/getImportTask',
      processGetImportTaskStatusAction: 'saManagement/getImportTaskStatus',
      processStartImportTaskAction: 'saManagement/startImportTask'
    }),
    startImportTask() {
      this.processStartImportTaskAction({
        import_id: this.importTask.import_id,
        import_task_id: this.importTask.id
      }).then((response) => {
        this.timeout = setTimeout(() => {
          this.getImportTask()
        }, 2000)
      })
    },
    getImportTask() {
      this.processGetImportTaskAction({
        import_id: this.importTask.import_id,
        task_id: this.importTask.id
      }).then((response) => {
        this.localImportTask = response.import_task
        this.batch = response.batch
        if (response.import_task.status === 'running') {
          this.timeout = setTimeout(() => {
            this.getImportTask()
          }, 2000)
        }
      })
    },
    deleteImportTask() {
      if (['error', 'waiting', 'finished'].includes(this.importTask.status)) {
        this.$emit('delete', this.importTask.id)
      }
    }
  }
}
</script>
